import React from 'react';
import { Page } from '../components/page';

export default () => (
  <Page>
    <div className="container w-75 pt-5">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Page>
);
